<template>
  <section class="pending-requests">

    <div class="container">
      <b-container>
        <b-row>
          <b-col>
            <p class="title">Solicitações pendentes</p>
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <div class="hite-box">
          <Tab
              ref="surgeries_tabs"
              :index="currentTabIndex"
              :keys="['Exames', 'Renovações']"
              @onChange="onChangeTab"
            >
              <BvExam v-if="currentTabIndex === 0" />
              <BvPrescription v-if="currentTabIndex === 1" />
            </Tab>
        </div>
      </b-container>
    </div>
  </section> 
</template>
<script>

export default {
  name: 'Dashboard',
  metaInfo: {
    title: 'Eyecare - Solicitações pendentes'
  },

  model: {
    prop: 'currentIndex',
    event: 'onChange'
  },

  components: {
    Tab: () => import('@/components/Tab.vue'),
    BvPrescription: () => import('@/modules/bvPendingRequests/views/BvPrescription.vue'),
    BvExam: () => import('@/modules/bvPendingRequests/views/BvExam.vue'),
  },
  data() {
    return {
      currentTabIndex: 0,
      tabs: [],
    }
  },
  methods: {
    onChangeTab(index) {
      this.currentTabIndex = index;
    },

  },
}
</script>
<style lang="scss" scoped>
.pending-requests {
  height: 100%;
  padding: 30px 24px;
  text-align: left;

  .title {
    text-align: start;
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
    margin-bottom: 27px;
  }

  .container {
    width: 100%;
    max-width: 100%;
    padding: 0;

    .white-box {
      width: 100%;
      background-color: var(--neutral-000);
      border-radius: 8px;
      margin-bottom: 16px;

      .content {
        padding: 24px;

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;

          .main-title {
            font-family: 'Red Hat Display';
            font-weight: 700;
            font-size: 18px;
            color: var(--dark-blue);

            span {
              color: var(--orange);
            }
          }
        }

        .tabs {
          margin: -30px 24px 0 24px;
          height: 45px;
          border-bottom: 1px solid var(--neutral-200);
          display: flex;
          align-items: center;

          .tab {
            font-weight: 700;
            font-size: 16px;
            color: var(--type-active);
            padding: 4px 24px 13px 24px;
            cursor: pointer;
            border-bottom: 3px solid var(--neutral-000);

            &.active {
              color: var(--orange);
              border-bottom: 4px solid var(--orange);
            }
          }
        }

        .filter {
          padding: 0 25px;
          margin-bottom: 30px;
          margin-top: -25px;

          label {
            font-weight: bold;
          }

          .search {
            display: flex;
            flex-direction: row;
            position: relative;
            margin: 10px 0 20px 0;

            .search-icon {
              position: absolute;
              width: 25px;
              margin-left: 15px;
              margin-top: 60px;
              z-index: 2;
              stroke: var(--neutral-500);
            }
          }

          .search-by-state {         
            input {
              margin-top: 5px;
              font-size: 16px;
              padding: 10px 10px;
              min-height: 38px;
              background-color: white !important;
            }

            svg {
              margin-top: 10px;
            }

            .state-label {
              margin-bottom: 8px !important;
            }

            .multiselect {
              background-color: #FF6B00;
              border: 1px solid var(--neutral-300);
              box-sizing: border-box;
              border-radius: 8px;

              .multiselect__tags {
                padding-right: 0px;
              }

              .multiselect__single {
                font-family: Nunito Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 150%;
                color: var(--type-active);
              }
            }
          }

          .periods-container {
            .select-container {
              padding: 5px 0;
            }

            input {
              padding-top: 5px;
              padding: 10px 10px;
              min-height: 38px;
              background-color: white !important;
            }

            svg {
              margin-top: 5px;
            }
          }
        }

        .patient-search {
          .multiselect__tags {
            margin-top: 5px;
            padding: 5px 0;
          }

          input {
            margin-top: 5px;
            padding: 10px 10px;
          }
        }

        .select-label {
          margin-bottom: 8px !important;
        }
        .tab-content {
          padding-bottom: 30px;

          .pad-left {
            padding-left: 28px !important;
          }
          .patient {
            display: flex;
            flex-direction: row;
            margin-left: 10px;
            margin-top: 5px;

            .patient-info {
              display: flex;
              flex-direction: row;
              align-items: center;
              align-content: center;
              flex-wrap: wrap;
              padding-left: 10px;
            }

            .patient-medical-record {
              width: 100%;

              a {
                cursor: pointer;
              }
            }

            .patient-age {
              font-size: 14px;
              align-self: flex-start;
            }
          }

          .eye-badge {
            background-color: #D9DFF2;
            width: 48px;
            margin-right: 8px;

            .eye-icon {
              width: 20px;
              padding-right: 5px;
              margin: 0 auto;
            }
          }

          .btn-pending-state {
            margin-top: 10px;
            font-size: 12px;
            border: none;

            &.btn-danger {
              background-color: #FFCECA !important;
              p {
                color: #D82828 !important;
              }
            }
            &.btn-success {
              background-color: #DBFFF0 !important;
              p {
                color: #00C773 !important;
              }
            }
            &.btn-warning {
              background-color: #FFEBCC !important;
              p {
                color: #525C7A !important;
              }
            }
          }

          .btn-refuse {
            background-color: #FFEBCC;
            color: #FF6B00;
            font-weight: bolder;
            font-size: 15px;
            border: none;
          }

          ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              padding: 24px 0;
              border-bottom: 1px solid var(--neutral-200);
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;

              .name {
                width: 240px;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--type-active);
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.swal2-modal {
  font-family: 'Nunito Sans';

  .swal2-title {
    font-size: 18px;
    color: var(--type-active);
    border: 1px solid var(--neutral-200);
    padding-bottom: 15px;
  }

  .swal2-select {
    margin-top: 1%;
    border-radius: 8px;
    padding: 0.375em 0.625em;
    border-color: var(--type-placeholder);
    font-weight: 400;
    color: var(--type-active);
    cursor: pointer;
  }

  .swal2-actions {
    justify-content: end;
    margin-right: 20px;
  }

  .swal2-html-container {
    margin-left: 20px;
    margin-bottom: 10px;
    text-align: left;
  }

  button {
    font-weight: bold;
  }

  .color-primary {
    color: var(--blue-500);
  }

  .color-danger {
    color: #FF6B00;
  }

  .btn-cancel {
    background: none;
    line-height: 24px; 
    color: #F63220;
  }
}
</style>